import $ from "jquery";
import http from "@/api/axios";
export default {
    name: "",
    components: {},
    data() {
        return {
            videoList: '',
            paginationParams: {
                limit: 12,
                page: 1
            },
            currentPage: 1,
            total: 0,
            loading: false,
            videoType: 1,
        };
    },
    methods: {
        alignCenter() {
            const w = document.body.clientWidth;
            const boxes = $(".cover-name")[0];
            if (boxes) {
                const boxWid = boxes.scrollWidth; //获取div的宽
                boxes.style.left = w / 2 - boxWid / 2 + "px";
            }
        },
        getVideo() {
            this.loading = true;
            http.get("/douyin/findAll", {
                params: {
                    ...this.paginationParams,
                    videoType:this.videoType,
                }
            }).then((res) => {
                    this.videoList = res.content;
                    this.total = res.totalElements;
                    console.log(this.videoList);
                    this.loading = false;
            })
        },
        windowOpen(url) {
            window.open(url);
        },
        changeVideoType(videoType) {
            this.videoType = videoType;
            this.getVideo();
        }
    },
    mounted() {
        const _this = this;
        _this.alignCenter();
        const link = window.location.href;

        let kind = sessionStorage.getItem('kind');
        if (link.indexOf('dynamic') > -1) {
            this.kind = '0';
            this.coverName = '公司动态';
            kind = '0';
        } else if (link.indexOf('monthly') > -1) {
            this.kind = '1';
            this.coverName = '月刊';
            kind = '1';
        } else if (link.indexOf('annual') > -1) {
            this.kind = '2';
            this.coverName = '年刊';
            kind = '2';
        }
        this.kind = kind;
        this.getVideo();

        window.onresize = function() {
            _this.alignCenter();
        };
    },
    computed: {},
    watch: {
        $route() {
            let kind = sessionStorage.getItem('kind');
            const link = window.location.href;
            if (link.indexOf('dynamic') > -1) {
                this.kind = '0';
                this.coverName = '公司动态';
                kind = '0';
            } else if (link.indexOf('monthly') > -1) {
                this.kind = '1';
                this.coverName = '月刊';
                kind = '1';
            } else if (link.indexOf('annual') > -1) {
                this.kind = '2';
                this.coverName = '年刊';
                kind = '2';
            }
            this.kind = kind;
            this.getNews(kind);
            this.paginationParams.page = 1;
        }
    }
};